(function( $ ) {

	/*
	*  new_map
	*
	*  This function will render a Google Map onto the selected jQuery element
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	function new_map( $el ) {

		// var
		var $markers = $el.find( '.marker' );

		// vars
		var args = {
			zoom: 16,
			center: new google.maps.LatLng( 0, 0 ),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [
				{
					"featureType": "administrative",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#002a50"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#268abc"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#173f63"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "labels.icon",
					"stylers": [
						{
							"color": "#09426d"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#002a50"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#268abc"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#002a50"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#09426d"
						}
					]
				},
				{
					"featureType": "poi",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"stylers": [
						{
							"color": "#173f63"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#268abc"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#173f63"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#268abc"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#094378"
						}
					]
				}
			]
		};

		// create map
		var map = new google.maps.Map( $el[ 0 ], args );

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each( function() {

			add_marker( $( this ), map );

		} );

		// center map
		center_map( map );

		// return
		return map;

	}

	/*
	*  add_marker
	*
	*  This function will add a marker to the selected Google Map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map ) {

		// var
		var latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );

		var mapIcon = {
			path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
			fillColor: 'yellow',
			fillOpacity: 0.8,
			scale: 1,
			strokeColor: 'gold',
			strokeWeight: 14
		};

		// create marker
		var marker = new google.maps.Marker( {
			position: latlng,
			map: map,
			icon: '/wp-content/uploads/2019/01/cwc-map-marker.png'
		} );

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if ( $marker.html() ) {
			// create info window
			var infowindow = new google.maps.InfoWindow( {
				content: $marker.html()
			} );

			// show info window when marker is clicked
			google.maps.event.addListener( marker, 'click', function() {

				infowindow.open( map, marker );

			} );
		}

	}
	// Center
	function center_map( map ) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ) {

			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );

		} );

		// only 1 marker?
		if ( map.markers.length == 1 ) {
			// set center of map
			map.setCenter( bounds.getCenter() );
			map.setZoom( 16 );
		}
		else {
			// fit to bounds
			map.fitBounds( bounds );
		}
	}
	// Document Ready
	var map = null;
	$( document ).ready( function() {
		$( '.acf-map' ).each( function() {
			map = new_map( $( this ) );
		} );
	} );

})( jQuery );