/*global
alert, confirm, console, prompt
*/
(function($) {

	"use strict";

	$('#scroll-bot').on('click tap', function() {
		var objDiv = document.getElementById("time-scroll");
		var objHeight = objDiv.scrollHeight;
		objDiv.scrollTop = objHeight;
	} );


})(jQuery);