/*global
alert, confirm, console, prompt
*/
(function($) {

  "use strict";

  $('.map-navigation').collapse();

	$( ".foot-toggle" ).append( $( "<svg version=\"1.1\" class=\"plus-toggle\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 25 25\" style=\"enable-background:new 0 0 25 25;\" xml:space=\"preserve\"><g><rect x=\"0.3\" y=\"12\" class=\"st0\" width=\"24.5\" height=\"1\"/><rect x=\"12\" y=\"0.3\" class=\"st0\" width=\"1\" height=\"24.5\"/></g></svg>" ) );

})(jQuery);