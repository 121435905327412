(function($) {

	"use strict";

 $(document).ready(function(){
	 $(".sticky-sidebar").sticky({topSpacing:150});
 });

	// $(document).on('scroll', function() {
	//
	// 	if($(this).scrollTop()>=$('#section-1').position().top){
	// 		$('.sticky-control').removeClass('active');
	// 		$('#control-1').addClass('active');
	// 	}
	//
	// 	if($(this).scrollTop()>=$('#section-2').position().top){
	// 		$('.sticky-control').removeClass('active');
	// 		$('#control-2').addClass('active');
	// 	}
	//
	//
	// 	if($(this).scrollTop()>=$('#section-3').position().top){
	// 		$('.sticky-control').removeClass('active');
	// 		$('#control-3').addClass('active');
	// 	}
	//
	// });



	$(document).ready(function() {
		$('.sticky-sidebar a[href*=#]').bind('click', function(e) {
			e.preventDefault(); // prevent hard jump, the default behavior

			var target = $(this).attr("href"); // Set the target as variable

			// perform animated scrolling by getting top-position of target-element and set it as scroll target
			$('html, body').stop().animate({
				scrollTop: $(target).offset().top -100
			}, 600, function() {
				// location.hash = target; //attach the hash (#jumptarget) to the pageurl
			});

			return false;
		});
	});

	$(window).scroll(function() {
		var scrollDistance = $(window).scrollTop();

		// Show/hide menu on scroll
		//if (scrollDistance >= 850) {
		//		$('nav').fadeIn("fast");
		//} else {
		//		$('nav').fadeOut("fast");
		//}

		// Assign active class to nav links while scolling
		$('.page-section').each(function(i) {
			if ($(this).position().top <= scrollDistance - 450) {
				$('.sticky-sidebar a.active').removeClass('active');
				$('.sticky-sidebar a').eq(i).addClass('active');
			}
		});
	}).scroll();


})(jQuery);