/*global
alert, confirm, console, prompt
*/
(function($) {

	"use strict";

	$( document ).ready(function() {
	$('.home-slider-01').owlCarousel({
		loop:false,
		margin:10,
		lazyLoad: true,
		URLhashListener:true,
		autoplay: false,
		touchDrag: false,
		pullDrag: false,
		mouseDrag: false,
		// autoplayTimeout: 6500,
		// autoplayHoverPause:true,
		startPosition: 'hs1-1',
		animateOut: 'fadeOut',
		responsive : {
			// breakpoint from 0 up
			0 : {
				items : 1,
				center:false,
				mergeFit: false,
			},
			// breakpoint from 1350 up
			1350 : {
				items : 1,
				center:true,
			}
		},
	});

	$('.home-slider-02').owlCarousel({
		// items:2,
		center: false,
		loop:true,
		margin:10,
		nav: true,
		navText: ['<svg version="1.1" id="prev_arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 70" style="enable-background:new 0 0 36 70;" xml:space="preserve"><polygon class="st0" points="34.9,69.5 0.4,35 34.9,0.5 35.6,1.2 1.8,35 35.6,68.8 "/></svg>','<svg version="1.1" id="next_arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 70" style="enable-background:new 0 0 36 70;" xml:space="preserve"><polygon class="st0" points="1.1,69.5 35.6,35 1.1,0.5 0.4,1.2 34.2,35 0.4,68.8 "/></svg>'],
		autoplay: true,
		autoplayTimeout: 7500,
		autoplayHoverPause:true,
		responsive : {
			// breakpoint from 0 up
			0 : {
				items : 1
			},
			// breakpoint from 1350 up
			1350 : {
				items : 1
			}
		},
	});

	$('.case-study-slider').owlCarousel({
		items:1,
		loop:true,
		margin:10,
		nav: true,
		navText: ['<svg version="1.1" id="prev_arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 70" style="enable-background:new 0 0 36 70;" xml:space="preserve"><polygon class="st0" points="34.9,69.5 0.4,35 34.9,0.5 35.6,1.2 1.8,35 35.6,68.8 "/></svg>','<svg version="1.1" id="next_arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 70" style="enable-background:new 0 0 36 70;" xml:space="preserve"><polygon class="st0" points="1.1,69.5 35.6,35 1.1,0.5 0.4,1.2 34.2,35 0.4,68.8 "/></svg>'],
		autoplay: true,
		autoplayTimeout: 7500,
		autoplayHoverPause:true,
		animateOut: 'fadeOut',
		responsive : {
			// breakpoint from 0 up
			0 : {
				items:1,
				center: false,
			},
			// breakpoint from 991 up
			991 : {
				items:1,
				center: true,
			}
		},
	});

	} );

	$( document ).ready(function() {
		$('#hslink-1, .home-slider-01 > .owl-dots > .owl-dot:nth-child(1) > span, .home-slider-01 > .owl-dots > .owl-dot:nth-child(1) > span').on('click tap', function() {
			$('.owl-links h2 > a').removeClass('active');
			$('#hslink-1').addClass('active');
		} );
		$('#hslink-2, .home-slider-01 > .owl-dots > .owl-dot:nth-child(2) > span, .home-slider-01 > .owl-dots > .owl-dot:nth-child(2) > span').on('click tap', function() {
			$('.owl-links h2 > a').removeClass('active');
			$('#hslink-2').addClass('active');
		} );
		$('#hslink-3, .home-slider-01 > .owl-dots > .owl-dot:nth-child(3) > span, .home-slider-01 > .owl-dots > .owl-dot:nth-child(3) > span').on('click tap', function() {
			$('.owl-links h2 > a').removeClass('active');
			$('#hslink-3').addClass('active');
		} );

	} );

})(jQuery);