/*global
alert, confirm, console, prompt
*/
if (typeof initMap === 'undefined') {
  function initMap() {
  }
}

(function ($) {

  "use strict";

  if (typeof initMap !== 'undefined') {
    // Controls the popover for the sites KML
    $('[data-toggle="popover"]').popover({
      content: function () {
        var content = $(this).attr('data-popover-content');
        return $(content).children('.popover-body').html();
      },
      title: function () {
        var title = $(this).attr('data-popover-content');
        return $(title).children('.popover-heading').html();
      }
    });

    //Toggles the KNL Popover on click event
    $('[data-close="popover"]').on('click', function () {
      $('[data-toggle="popover"]').popover({
        trigger: 'focus'
      });
    });
  }


  $(function () {
	  if ( typeof DataTable !== 'undefined' ) {
		  // Controls the custom Data Table created
		  $( '[data-table]' ).DataTable( {
			  "paging":    false,
			  "info":      false,
			  "searching": false,
		  } );
	  }

    $('[data-kml-toggle]').on('click', function () {
      $('.kml-items-container').toggle();
    });

  });

    $('#accordion').on('show', function (e) {
         $(e.target).prev('.accordion-heading').find('.accordion-toggle').addClass('active');
    });
    
    $('#accordion').on('hide', function (e) {
        $(this).find('.accordion-toggle').not($(e.target)).removeClass('active');
    });
         

})(jQuery);

(function($){
        $(window).on("load",function(){
       $(".detail-desc").mCustomScrollbar({
	      theme:"dark-3"
    });
        });
})(jQuery);