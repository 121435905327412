/*global
alert, confirm, console, prompt
*/

(function( $ ) {

	"use strict";

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		//>=, not <=
		if (scroll >= 100) {
			//clearHeader, not clearheader - caps H
			$("#top_header").addClass("scrolled");
		} else {
			$("#top_header").removeClass("scrolled");
		}
	}); //missing );

	$(document).ready(function (){
		$( '#scroll-arrow' ).on( 'click tap', function() {
			$('html, body').animate({
				scrollTop: $("#breadcrumbs").offset().top - 60
			}, 1000);
		});
	});

})( jQuery );